import React from "react";
import '../index.css';
import { IModalProps } from "../interface";


interface IProps extends IModalProps{
    visible:boolean,
    showCancel?:boolean,
    showConfirm?:boolean,
    icon?:React.ReactNode,
    children?:React.ReactNode,
    maxHeight?:number
}

const ModalMessage = (props:IProps)=>{


    const {title, content, onOk, onCancel, showCancel, showConfirm, icon, okText = '确认'} = props;

    const onConfirmBtnClick = (e:any)=>{
        e.stopPropagation()
        onOk&&onOk();
    }


    const onCancelBtnClick = (e:any)=>{
        e.stopPropagation();
        onCancel&&onCancel();
    }


    return (
        <div className='w-100 relative'>
            {title && <div className="zc-dialog-title">{icon}{title}</div>}
            <div className='modal-close-icon' onClick={onCancelBtnClick}>×</div>
            <div className="zc-dialog-content mt10" style={{maxHeight: props.maxHeight || 'auto'}}>{content}</div>
            <div className="flex flex-row justify-end mt10">
                {showCancel && <button className="zy-button mr10 " onClick={onConfirmBtnClick}>{okText}</button>}
                {showConfirm && <button className="ghost-button" onClick={onCancelBtnClick}>取消</button>}
            </div>
        </div>
    )
}

export default ModalMessage;