import React, {useEffect, useState} from "react";
import CaptchaRefresh from "./refresh";
import CaptchaDrag from "./drag";
import CaptchaImage from "./content";
import './index.less'
import {captchaProps} from "./interface";


interface Props extends captchaProps {
    onFinish?: <T>(params: T) => void
    status?: number
    setVisible: any
}

let move: boolean = false
let x: number = 0
let y: number = 0
let startSlidingTime: any
let isFinish: boolean = false

const SlideCaptcha = (props: Props) => {

    const {
        width = 340,
        height = 212,
        handlerIconWidth = 16,
        handlerIconHeigth = 16,
        barHeight = 40,
        circle = false,
        radius = '4px',
        progressBarBg = '#76c61d',
        background = '#eee',
        text = '按住滑块拖动',
        successTip = '验证通过，超过80%用户',
        failTip = '验证未通过，拖动滑块将悬浮图像正确合并',
        onFinish,
        src,
        sliderSrc,
        status = 1,
        setVisible
    } = props


    const [isPassing, setIsPassing] = useState<number>(1)
    const [showGenerateLoadding, setShowGenerateLoadding] = useState<boolean>(false)
    const [showVerifyLoadding, setShowVerifyLoadding] = useState<boolean>(false)
    const [isOk, setIsOk] = useState<boolean>(false)
    const [tracks, setTracks] = useState<any>([])
    const [dragLeft, setDragLeft] = useState<number>(0) // 滑块偏移
    const [barWidth, setBarWidth] = useState<number>(0) // 滑块拖动覆盖


    // 开始拖动
    const handleDragStart = (e: any) => {
        if (isPassing === 1 && src && sliderSrc && !isFinish) {
            window.addEventListener("touchmove", handleDragMoving);
            window.addEventListener("touchend", handleDragFinish);
            window.addEventListener("mousemove", handleDragMoving);
            window.addEventListener("mouseup", handleDragFinish);
            move = true
            x = e.pageX || e.touches[0].pageX
            y = e.pageY || e.touches[0].pageY
            startSlidingTime = new Date()

        }
    }
    // 拖动
    const handleDragMoving = (e: any) => {
        if (move && isPassing === 1 && src && sliderSrc && !isFinish) {
            let _x = (e.pageX || e.touches[0].pageX) - x;
            let _y = (e.pageY || e.touches[0].pageY) - y;
            setDragLeft(_x)
            setBarWidth(_x + barHeight / 2)
            const _tracks = tracks
            _tracks.push({
                x: _x,
                y: _y,
                t: new Date().getTime() - startSlidingTime.getTime(),
            })
            setTracks(_tracks)
        }
    }

    // 停止
    const handleDragFinish = () => {
        if (move && isPassing === 1 && src && sliderSrc && !isFinish) {
            move = false
            isFinish = true
            removeEventListeners()
            onFinish?.({
                backgroundImageWidth: width,
                backgroundImageHeight: height,
                sliderImageWidth: 68,
                sliderImageHeight: height,
                startTime: startSlidingTime,
                endTime: new Date(),
                tracks: tracks,
            })
        }
    }

    // 移除鼠标事件
    const removeEventListeners = () => {
        window.removeEventListener("touchmove", handleDragMoving);
        window.removeEventListener("touchend", handleDragFinish);
        window.removeEventListener("mousemove", handleDragMoving);
        window.removeEventListener("mouseup", handleDragFinish);
    }

    const handleRefresh = () => {
        x = 0
        y = 0
        isFinish = false
        move = false
        startSlidingTime = null
        setTracks([])
        setBarWidth(0)
        setDragLeft(0)
        setIsOk(false)
        setIsPassing(1)
        setShowGenerateLoadding(false)
        setShowVerifyLoadding(false)
        removeEventListeners()
    }

    useEffect(() => {
        if (status) {
            setIsPassing(status)
        }
        if (status === 3) {
            handleRefresh()
        }
    }, [status])

    return (
        <div className='captcha'>

            {/* 刷新 */}
            <CaptchaRefresh handleRefresh={handleRefresh} setVisible={setVisible}/>
            {/* 图像 */}
            <CaptchaImage
                isPassing={isPassing}
                failTip={failTip}
                successTip={successTip}
                showGenerateLoadding={showGenerateLoadding}
                showVerifyLoadding={showVerifyLoadding}
                width={width}
                height={height}
                src={src}
                sliderSrc={sliderSrc}
                dragLeft={dragLeft}
            />
            {/* 拖动 */}
            <CaptchaDrag
                text={text}
                handlerIconHeigth={handlerIconHeigth}
                handlerIconWidth={handlerIconWidth}
                isOk={isOk}
                barHeight={barHeight}
                width={width}
                circle={circle}
                progressBarBg={progressBarBg}
                radius={radius}
                background={background}
                handleDragStart={handleDragStart}
                dragLeft={dragLeft}
                barWidth={barWidth}
            />

        </div>
    )
}
export default SlideCaptcha