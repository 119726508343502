

// 获取[ui=control]标签上的参数 data- target app
export const getUITagParams = (item:any)=>{
  const attr: NamedNodeMap = item?.attributes || [];
  const params: any = {};
  let trigger = "";
  let app = "";
  for(let i = 0;i<attr.length;i++){
    const attrIt = item.attributes[i];
    if(attrIt.localName.includes('data-')){
      params[attrIt.localName.replace('data-','')] = attrIt.value;
    }
    if(attrIt.localName == 'trigger'){
      trigger = attrIt.value;
    }
    if(attrIt.localName == 'app'){
      app = attrIt.value;
    }
  }
  return {params,trigger:trigger.length==1?trigger[0]:trigger,app};
}

export const ZDom = class{
  current:any
  constructor(dom:any){
      this.current = dom;
  }
  /**
   * 
   * @param key  ".wrap tr td"  找到classNmae = 'wrap' 标签 child tr  child td
   * @returns 
   */
  $ = (key:string,specialDom?:any)=>{
    let selectDataDom = key.split(' ').reduce((r:any,item:any)=>{
        let splitData = undefined;
        if(item.includes('/')){
          splitData = {index:item.split('/')[1]*1-1,name:item.split('/')[0]}
        }

        switch(item.charAt(0)) {
          case '.':
            r = splitData?(r.getElementsByClassName(splitData.name.replace('.',''))[splitData.index]):(r.getElementsByClassName(item.replace('.','')));
            break;
          case '#':
            r = r.getElementByTagId(item.replace('#',''));
            break;
          default:
            r = splitData?(r.getElementsByTagName(splitData.name)[splitData.index]):r.getElementsByTagName(item);
            break;
          }
          return r;
    },specialDom||this.current)
    return selectDataDom.length==1?selectDataDom[0]:selectDataDom;
  }
}









