import React from "react";
import {captchaProps} from "@/components/SlideCaptcha/interface";

interface Props extends captchaProps {
    showVerifyLoadding: boolean
    showGenerateLoadding: boolean
    isPassing: number
    dragLeft: number
}

const CaptchaImage = (props: Props) => {

    const {
        showVerifyLoadding,
        showGenerateLoadding,
        isPassing,
        successTip,
        width,
        height,
        src,
        sliderSrc,
        dragLeft
    } = props

    return (
        <div className="captcha__main" style={{
            width,
            height,
            position: "relative",
            overflow: "hidden"
        }}>
            {src && <img src={src} className="captcha_background" alt='' style={{width, height}}/> || ''}
            {sliderSrc &&
            <img src={sliderSrc} className="captcha_slider" alt='' style={{left: dragLeft, height, width: 68}}/> || ''}

            {isPassing > 1 && <div className="captcha_message">
                <div className="captcha_message__icon">
                    {isPassing === 2 &&
                    <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="#fff" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round"
                           strokeLinejoin="round">
                            <path
                                d="M22.776 4.073A13.2 13.2 0 0 0 14 .75C6.682.75.75 6.682.75 14S6.682 27.25 14 27.25 27.25 21.318 27.25 14c0-.284-.009-.566-.027-.845"></path>
                            <path d="M7 12.5l7 7 13-13"></path>
                        </g>
                    </svg>
                    || isPassing === 3 &&
                    <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="#fff" strokeWidth="1.5" fill="none" fillRule="evenodd">
                            <circle cx="14" cy="14" r="13.25"></circle>
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M8.75 8.75l10.5 10.5M19.25 8.75l-10.5 10.5"></path>
                        </g>
                    </svg>
                    }
                </div>
                <div className="captcha_message__text">  {isPassing === 2 && successTip} </div>
            </div> || ''}

            {showGenerateLoadding &&
            <div className="captcha_message loadding">
                <div className="captcha_message__icon captcha_message__icon--loadding"/>
                <div className="captcha_message__text"> 加载中...</div>
            </div>
            || ''}

            {showVerifyLoadding &&
            <div className="captcha_message">
                <div className="captcha_message__icon captcha_message__icon--loadding"/>
                <div className="captcha_message__text"/>
            </div>
            || ''}

        </div>
    )
}
export default CaptchaImage