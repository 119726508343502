import React from "react";
import './index.less'
import './zcicon'

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, ''> {
  type: 'icon-success'|'icon-warn'|'icon-error'|'icon-empty'|'icon-back'|'icon-search'|'icon-exit'|'icon-delete'
  style?:any     
  className?:any
}



const ZIcon = (props:Props) => {

  return (
    <svg className={`icon ${props.className}`} aria-hidden="true" style={props.style}>
      <use xlinkHref={`#${props.type}`}></use>
    </svg>
  )
}

export default  ZIcon;
