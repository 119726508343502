import React from "react";
import {captchaProps} from "@/components/SlideCaptcha/interface";

interface Props extends captchaProps {
    isOk: boolean
    barHeight: number
    handleDragStart: any
    dragLeft: number
    barWidth: number
}

const CaptchaDrag = (props: Props) => {
    const {
        text,
        handlerIconWidth,
        handlerIconHeigth,
        isOk,
        barHeight,
        width,
        circle,
        progressBarBg,
        radius,
        background,
        handleDragStart,
        dragLeft,
        barWidth,
    } = props


    const progressBarStyle = () => {
        return {
            background: progressBarBg,
            height: barHeight + "px",
            width: barWidth,
            borderRadius: circle
                ? barHeight / 2 + "px 0 0 " + barHeight / 2 + "px"
                : radius,
        }
    }
    const dragVerifyStyle = () => {
        return {
            width,
            background,
            height: barHeight + "px",
            lineHeight: barHeight + "px",
            borderRadius: circle ? barHeight / 2 + "px" : radius,
        };
    }
    return (
        <div className="captcha__bar" style={dragVerifyStyle()}>
            <div className={`captcha_progress_bar ${isOk && 'goFirst2' || ''}`} style={progressBarStyle()}/>
            <div className="captcha_progress_bar__text" style={{width, height: barHeight}}>  {text}  </div>
            <div className={`captcha_handler ${isOk && 'goFirst' || ''}`}
                 style={{width: barHeight, height: barHeight - 2, left: dragLeft}}
                 onMouseDown={handleDragStart}
                 onTouchStart={handleDragStart}
            >
                <svg style={{width: handlerIconWidth, height: handlerIconHeigth}} viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="819">
                    <path
                        d="M500.864 545.728a47.744 47.744 0 0 0 6.72-48.896 24.704 24.704 0 0 0-4.48-8.384L240.256 193.088a34.24 34.24 0 0 0-28.608-17.408 34.24 34.24 0 0 0-25.856 12.864 46.592 46.592 0 0 0 0 59.52l238.08 264.512-238.08 264.512a46.592 46.592 0 0 0-1.088 59.52 32 32 0 0 0 50.56 0l265.6-290.88z"
                        p-id="820"></path>
                    <path
                        d="M523.84 248.064l236.992 264.512-238.08 264.512a46.592 46.592 0 0 0 0 59.52 32 32 0 0 0 50.56 0l265.6-292.608a47.744 47.744 0 0 0 6.72-48.832 24.704 24.704 0 0 0-4.48-8.448L578.304 191.36a34.24 34.24 0 0 0-55.552-2.816 46.592 46.592 0 0 0 1.088 59.52z"
                        p-id="821"></path>
                </svg>
            </div>

        </div>
    )
}
export default CaptchaDrag