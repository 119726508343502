import React, {useEffect, useState} from "react";
import SlideCaptcha from "@/components/SlideCaptcha";
import './index.less'

interface Props {
    onFinish: any
    sliding: any
    status: number
    setVisible: any
}

const Capt = (props: Props) => {
    const {onFinish, sliding, status, setVisible} = props
    const [src, setSrc] = useState<string>('')       // 滑块背景图
    const [sliderSrc, setSliderSrc] = useState<string>('') // 滑块缺口图
    const [captId, setCaptId] = useState<string>('') // 滑块id


    // 获取滑动验证码
    const initCapt = (res: any) => {
        setSrc(res.backgroundImage)
        setSliderSrc(res.sliderImage)
        setCaptId(res.id)
    }

    useEffect(() => {
        if (sliding) {
            initCapt(sliding)
        }
    }, [sliding])

    return (
        <div className='capt-modal'>
            <SlideCaptcha onFinish={(track: any) => onFinish?.({id: captId, track})} src={src} sliderSrc={sliderSrc}
                          status={status} height={160} setVisible={setVisible}/>
        </div>
    )
}

export default Capt