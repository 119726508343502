import React from "react";
import './index.less';
import Login from "./login";

interface IProps {
    children?:any
}

// 登录页等公共页
const BaseApp = (props:IProps)=>{
      return (
          <div>
              <Login/>
              {props.children}
          </div>
      )
}

export default BaseApp;