import {Message} from '@/components/Messsage';
import axios from 'axios';
// import {object2Query, query2Object} from "@/utils/ZUtils";
/**
 * 自定义Axios实例
 */
const request: any = axios.create({
    baseURL: "",
    timeout: 60000,
});
// 请求防抖
let list: Record<string, boolean> = {}

// 添加请求拦截器
request.interceptors.request.use(function (config: any) {
    if (list[config.url]) {
        return
    }
    list[config.url] = true
    config.headers = {
        // "token": get('XSRF-TOKEN')
        "X-Requested-With": 'XMLHttpRequest'
    };
    return config;
}, (error: any) => {
    console.log(error)
    return Promise.reject(error);
});


request.interceptors.response.use((response: any) => {
    setTimeout(() => {
        list = {}
    }, 200)
    if (response.data.state) {
        return response.data;
    } else {
        if (response.data.message && !response.request.responseURL.includes('deliverskill/ChangePDF')) {
            Message.error(response.data.message);
        }
        if (response.data.message) {
            // Message.error(response.data.message);
            return Promise.reject(response);
        }
    }
}, (error: any) => {
    if (Object.keys(list)?.length) {
        list = {}
        return
    }
    list = {}
    console.log(error)
    if (error.code == 401) {
        localStorage.removeItem("token");
    }
    // Message.error('接口异常')
    if (error && error.response) {
        console.log(error.response)
    }
    return Promise.reject(error);
});


// 定义对外Get、Post、File请求
export default request;