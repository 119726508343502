import React, { useEffect } from "react";
import '../index.css';
import { IModalProps } from "../interface";


interface IProps extends IModalProps{
    visible:boolean,
    showCancel?:boolean,
    showConfirm?:boolean,
    icon?:React.ReactNode
}

const MessageTips = (props:IProps)=>{


    const { title,icon,during,onCancel } = props;
 
    useEffect(()=>{
        setTimeout(()=>{
            onCancel&&onCancel();
        },during||1000)
    },[])


    return (
           <span className="zc-dialog-tips-title">
               {icon}
             {title}
           </span>
    )
}

export default MessageTips;