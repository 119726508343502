// import { isParentDom } from "@/components/ZCUtil";
import React, {  useRef } from "react";
import '../index.css';
import { IModalProps } from "../interface";
import ModalMessage from "../Message";
import { ModalMessageTypes } from "../Render";
import MessageTips from "../Tips";


interface IProps extends IModalProps{
    visible:boolean,
    showCancel?:boolean,
    showConfirm?:boolean,
    icon?:React.ReactNode,
    children?:React.ReactNode,
    type?:ModalMessageTypes,
    width?:number,
    maxHeight?: any
}

const ModalWrap = (props:IProps)=>{

    const {onCancel, visible, onOk, type, width} = props;
    const containerRef = useRef<any>();

    const onCancelBtnClick = (e?:any)=>{
        e?.stopPropagation()
        onCancel && onCancel();
    }

    const onOkCallBack = ()=> {
        onOk && onOk()
        onCancel && onCancel();
    }

    const doCancel = (e?:any)=> {
        // if(e&&!isParentDom(e.target,containerRef.current)){
        //     onCancel && onCancel()
        // }
    }
    return (
        <>
            <div className={`zc-dialog-mask ${!visible ? 'zc-dialog-mask-wrap-feedout' : ''}`} onClick={doCancel}>
                <div ref={containerRef} style={{width}}
                     className={`zc-dialog-wrap  ${!visible ? 'zc-dialog-wrap-feedout' : ''}`}>
                    {type !== 'tips' &&
                    <ModalMessage {...props} visible={props.visible} onCancel={onCancelBtnClick} onOk={onOkCallBack}/>}
                    {type == 'tips' && <MessageTips {...props} visible={props.visible} onCancel={onCancelBtnClick}/>}
                </div>
            </div>
        </>
    )
}

export default ModalWrap;