import React from "react";

interface Props {
    handleRefresh: () => void
    setVisible: any
}

const CaptchaRefresh = (props: Props) => {
    // const {setVisible} = props

    return (
        <div className="captcha__actions">
            <span>滑动验证</span>
            {/*<div className='cursor-point' onClick={() => setVisible(false)}>X</div>*/}
            {/*<div className="captcha__action" onClick={handleRefresh}>*/}
            {/*    <svg fill="#505050" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M10,4 C12.0559549,4 13.9131832,5.04358655 15.0015086,6.68322231 L15,5.5 C15,5.22385763 15.2238576,5 15.5,5 C15.7761424,5 16,5.22385763 16,5.5 L16,8.5 C16,8.77614237 15.7761424,9 15.5,9 L12.5,9 C12.2238576,9 12,8.77614237 12,8.5 C12,8.22385763 12.2238576,8 12.5,8 L14.5842317,8.00000341 C13.7999308,6.20218044 12.0143541,5 10,5 C7.23857625,5 5,7.23857625 5,10 C5,12.7614237 7.23857625,15 10,15 C11.749756,15 13.3431487,14.0944653 14.2500463,12.6352662 C14.3958113,12.4007302 14.7041063,12.328767 14.9386423,12.4745321 C15.1731784,12.6202971 15.2451415,12.9285921 15.0993765,13.1631281 C14.0118542,14.9129524 12.0990688,16 10,16 C6.6862915,16 4,13.3137085 4,10 C4,6.6862915 6.6862915,4 10,4 Z" fillRule="nonzero"></path></svg>*/}
            {/*    <div className="captcha__action__text"> 刷新 </div>*/}
            {/*</div>*/}
        </div>
    )
}
export default CaptchaRefresh