import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { IModalProps } from "./interface";
import DialogRender from "./Render";
import ModalWrap from "./Wrap";


interface IModalComp extends IModalProps {
    children?: React.ReactNode,
    visible: boolean,
    width?: any,
    footer?: boolean,
    maxHeight?: any
}


const Modal = (props: IModalComp) => {
    const {onCancel, onOk, visible, footer} = props;
    const doCancel = () => onCancel?.()
    useEffect(()=>{
        if(visible){
            document.body.setAttribute('class', 'zc-modal-scrolling');
        }else{
            document.body.removeAttribute('class');
        }

        // 按下esc 隐藏弹窗 
        document.addEventListener("keydown",(e)=>{
            if(e.code=='Escape'){
                onCancel && onCancel()
            }
        });
        return ()=>{
            document.removeEventListener("keydown",()=>{});
        }
    },[visible])





    return (
        <>
            {
                createPortal(
                    <ModalWrap
                        maxHeight={props.maxHeight}
                        width={props.width || 700}
                        visible={visible}
                        title={props.title}
                        content={props.children}
                        showConfirm={footer != false}
                        showCancel={footer != false}
                        onCancel={doCancel}
                        onOk={() => {
                            onOk && onOk();
                            doCancel();
                        }}/>
                    , document.body)
            }
        </>
    )
};


Modal.confirm = (e: IModalProps) => DialogRender(e, 'confirm');
Modal.error = (e: IModalProps) => DialogRender(e, 'error')
Modal.warn = (e: IModalProps) => DialogRender(e, 'warn')
Modal.tips = (e: string, during?: number) => DialogRender({title: e, during: during}, 'tips')
Modal.info = (e: IModalProps) => DialogRender(e, 'warn')

export default Modal;

