import React, { useReducer } from "react";


interface IProps{
    children?:any
}

export interface IAppState{
    isLogin?:any,
    showLogin?:any
}
export enum APPEvent { SETISLOGIN,SHOWLOGIN }


export const AppContext = React.createContext({});

export const AppProvider = (props:IProps)=>{

    const { children } = props;
    const reducer = (state: any,action:{type:APPEvent,state?:IAppState}) => {
      switch (action.type) {
          case APPEvent.SETISLOGIN:
              return {...state, isLogin:action.state?.isLogin}
          case APPEvent.SHOWLOGIN:
              return {...state, showLogin:action.state?.showLogin};
          default:
              return state;
      }
  }

  const [state, dispatch] = useReducer(reducer, {
    isLogin:undefined,//当前活动项
    showLogin:false
  });

  

  return (
      <AppContext.Provider value={{state, dispatch}}>
            {children}   
      </AppContext.Provider>
  )

}