import React, { ReactNode, useEffect, useMemo } from "react";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import ZIcon from "../Icon";
import './index.css'


export type MessageProps = {
	rootDom: HTMLElement; //这个用来干掉parentDom 这个可以常驻
	parentDom: Element | DocumentFragment; //这个是挂载点 要unmount卸载 完毕后卸载挂载点 注意！一共2步卸载，别漏了
	content: ReactNode;
    type:'success'|'error'|'warn'
};
export type IMessage = {
    success?:any,
    warn?:any,
    error?:any
};


let wrap: HTMLElement;
export const createMessage = (props:{type?:any}) => {
	return (content: ReactNode) => {
		if (typeof document === "undefined") {
			return;
		}
		if (!wrap) {
			//如果有的话，说明已经调用过这个函数了，这个空div就可以一直复用
			wrap = document.createElement("div");
			wrap.style.cssText =   `line-height:1.5;
                                    text-align:center;
                                    color: #333;
                                    box-sizing: border-box;
                                    margin: 0;
                                    padding: 0;
                                    list-style: none;
                                    position: fixed;
                                    dispaly:flex;
                                    flex-direction:column;
                                    z-index: 100000;
                                    width: 100%;
                                    top: 16px;
                                    left: 0;
                                    pointer-events: none;`;
            if (wrap) {
                document.body && document.body.appendChild(wrap); //挂body上
            }
		}
		const divs = document.createElement("div");
		wrap.appendChild(divs);
        const { type } = props;

        ReactDOM.render(
			<MessageComp rootDom={wrap} type={type} parentDom={divs} content={content} />,
			divs
		);
	};
};



export const Message:IMessage = {};
Message.success = createMessage({type:'success'});
Message.warn =    createMessage({type:'warn'});
Message.error =   createMessage({type:'error'});




export function MessageComp(props: MessageProps) {
	const { rootDom, parentDom, content , type } = props;

	const unmount = useMemo(() => {
		return () => {
			if (parentDom && rootDom) {
				unmountComponentAtNode(parentDom);
				rootDom.removeChild(parentDom);
			}
		};
	}, [parentDom, rootDom]);


	useEffect(() => {
		setTimeout(() => {
			unmount();
		}, 2000);
	}, [unmount]);

	return (
            <ul>
                <li style={{marginTop:'30px'}}>
                    <div  className="message-item-wrap">
                    <ZIcon type={`icon-${type}`} style={{fontSize:'18px'}}/>
                    <span className="message-content">{content}</span>  
                    </div>
                </li>
            </ul>
        );
}
