import '@/assets/css/index.less'
import { ZCProvider } from "@/components/ZCContext"
import React from "react"
import ReactDOM from "react-dom"
import { AppProvider } from "./context"
import { getUITagParams, ZDom } from "./helper"
import { IPage, IRenderItem, IUIAttrParams } from "./interface"

// 插入单个app

const insertApp = (tagParams: IUIAttrParams, uidom: any, slotDom: any, getComp?: any) => {
  const Zdom = new ZDom(uidom);
  ReactDOM.render(
    <React.StrictMode>
      <AppProvider>
        <ZCProvider>
          {getComp({...tagParams, $: Zdom.$, uidom})}
        </ZCProvider>
      </AppProvider>
    </React.StrictMode>,
    slotDom
  )
}

class InsertApps {
  renderList: IRenderItem[]
  getComp?: any

  constructor(comp: any) {
    this.renderList = [];
    this.getComp = comp;
  }

  render = (type?: number) => {
    if (type === 1) {
      this.renderSingle()
    } else if (type === 2) {
      this.renderSingle()
    } else {
      this.renderTree();
    }
  }

  renderSingle = () => {
    for (let i = 0; i < this.renderList.length; i++) {
      const item = this.renderList[i];
      insertApp(item.params, item.uiDom, item.slotDom || item.uiDom, this.getComp);
    }
  }
  //
  // renderTest = (RenderItem: IRenderItem) => {
  //   // for (let i = 0; i < this.renderList.length; i++) {
  //   const item = RenderItem;
  //   insertApp(item.params, item.uiDom, item.slotDom || item.uiDom, this.getComp);
  //   // }
  // }


  renderTree = () => {
    // 获取最外层app的 dom 
    const mainAppRenderItemIndex = this.renderList.findIndex(item => item.slotDom.length > 0);
    // 最外层所有的 slotDom
    const slotDomArray = this.renderList[mainAppRenderItemIndex].slotDom;
    // 过滤其他child的 slotDom 剩下的就是 最外层的 slotDom
    const currentSlotDom = slotDomArray.filter((item: any) => {
      return !this.renderList.some(rItem => {
        return rItem.slotDom == item
      })
    });
    // 替换最外层 slotDom
    this.renderList[mainAppRenderItemIndex].slotDom = currentSlotDom[0]
    //遍历插入
    for (let i = 0; i < this.renderList.length; i++) {
      const item = this.renderList[i];
      insertApp(item.params, item.uiDom, item.slotDom || item.uiDom, this.getComp);
    }
  }
  addRenderItem = (RenderItem: IRenderItem) => {
    this.renderList.push(RenderItem)
  }
}

export const AppRender = (pageConfig: IPage, getComp?: any) => {
  const root: any = document.querySelectorAll('*[ui=control]');
  const uiList = Array.from(root);
  // 1：遍历所有的节点： 要求 一个ui=control 对应一个 control='slot'; 
  // 2：例外情况：如果 没有 control=slot 则会默认添加一个（覆盖当前节点）。 
  // 3：!! 嵌套的情况下必须一一对应，不可省略control=slot  !!多层嵌套暂不支持
  const insertApps = new InsertApps(getComp);
  for (let i = 0; i < uiList.length; i++) {
    const item: any = uiList[i];
    const tagParams = getUITagParams(item);
    if (pageConfig.type === 1) {
      // ui='contrl'单个：
      const slotDom = item.querySelector('[control=slot]');
      const renderItem: IRenderItem = {
        app: tagParams.app,
        uiDom: item,
        slotDom,
        params: tagParams,
        jsxPath: pageConfig.app
      }
      insertApps.addRenderItem(renderItem)
    } else if (pageConfig.type === 2) {
      const slotDom = item.querySelector('[control=slot]');
      const renderItem: IRenderItem = {
        app: pageConfig.app[i],
        uiDom: item,
        slotDom,
        params: {...tagParams, jsxPath: pageConfig.app[i]},
        jsxPath: pageConfig.app[i]
      }
      insertApps.addRenderItem(renderItem)
    } else {
      // ui='contrl'嵌套：
      const slotDoms = item.querySelectorAll('*[control=slot]');
      const slotDomArray = Array.from(slotDoms);
      const renderItems: IRenderItem = {
        app: tagParams.app,
        uiDom: item,
        slotDom: slotDomArray.length == 1 ? slotDomArray[0] : slotDomArray,
        params: {...tagParams},
        jsxPath: pageConfig.app[tagParams.app]
      }
      insertApps.addRenderItem(renderItems)
    }
  }
  insertApps.render(pageConfig.type);
}

// export const GlobalRender = (pageConfig: { app: string | [], type: number }, getComp?: any) => {
//   const root: any = document.querySelectorAll('*[ui=gcontrol]');
//   const uiList = Array.from(root);
//   // 1：遍历所有的节点： 要求 一个ui=control 对应一个 control='slot';
//   // 2：例外情况：如果 没有 control=slot 则会默认添加一个（覆盖当前节点）。
//   // 3：!! 嵌套的情况下必须一一对应，不可省略control=slot  !!多层嵌套暂不支持
//   const insertApps = new InsertApps(getComp);
//   // const isSingle = (typeof pageConfig.app == 'string')
//   for (let i = 0; i < uiList.length; i++) {
//     const item: any = uiList[i];
//     const tagParams = getUITagParams(item);
//     if (pageConfig.type === 1) {
//       // ui='contrl'单个：
//       const slotDom = item.querySelector('[gcontrol=slot]');
//       const renderItem: IRenderItem = {
//         app: tagParams.app,
//         uiDom: item,
//         slotDom: slotDom,
//         params: {...tagParams},
//         jsxPath: pageConfig.app
//       }
//       insertApps.addRenderItem(renderItem)
//     } else {
//       // ui='contrl'嵌套：
//       const slotDoms = item.querySelectorAll('*[gcontrol=slot]');
//       const slotDomArray = Array.from(slotDoms);
//       const renderItems: IRenderItem = {
//         app: tagParams.app,
//         uiDom: item,
//         slotDom: slotDomArray.length == 1 ? slotDomArray[0] : slotDomArray,
//         params: {...tagParams},
//         jsxPath: pageConfig.app[tagParams.app]
//       }
//       insertApps.addRenderItem(renderItems)
//     }
//   }
//   insertApps.render(pageConfig.type);
// }