import request from  './lib/request';

export interface IExtraOrder {
	id?: number,
	userId?: number,
	userName?: string,
	phone?: string,
	name?: string,
	skuNum?: number,
	box?: number,
	deliverNos?: [],
	remark?: string,
	files?: [],
	status?: number,
	payStatus?: number,
	fee?: number,
	types?: [],
	ptime?: string,
	paytime?: string,
	done?: string,
	boxed?: boolean,
	extraItems?: [],
	useBoxes?: number,
	payfee?: number
}
export interface IGoods {
	id?: number,
	userId?: number,
	sku?: string,
	userName?: string,
	cn?: string,
	en?: string,
	cnCode?: string,
	usCode?: string,
	weight?: number,
	declare?: number,
	material?: string,
	brand?: string,
	brandType?: string,
	pic?: string,
	specs?: string,
	specAddPirce?: string,
	pdf?: string,
	exam?: number,
	adminRemark?: string,
	userRemark?: string,
	url?: string,
	specials?: [],
	stock?: number,
	lastShipment?: string
}
export interface IPack {
	id?: number,
	pid?: number,
	userId?: number,
	userName?: string,
	size?: [],
	kg?: number,
	feeKg?: number,
	cns?: string,
	remark?: string,
	ptime?: string,
	packItems?: []
}
export interface IWareBook {
	id?: number,
	loginId?: number,
	userName?: string,
	code?: string,
	memo?: string,
	ptime?: string,
	utime?: string,
	status?: number
}
export interface IWareParam {
	pageSize?: number,
	page?: number,
	total?: number,
	remote?: boolean,
	code?: string,
	country?: string
}
export interface ITimeBook {
	id?: number,
	loginId?: number,
	userName?: string,
	timeId?: number,
	memo?: string,
	ptime?: string,
	utime?: string,
	status?: number
}
export interface ISpeAddParam {
	name?: string,
	country?: string,
	method?: number
}
export interface IShipParam {
	name?: string,
	type?: string,
	country?: string
}

export interface IResQuery {
	pageSize?: number,
	page?: number,
	total?: number,
	key?: string,
	type?: any
}

export interface IShipOrder {
	extraId?: number,
	specadd?: number,
	pid?: number,
	child?: number,
	id?: number,
	userId?: number,
	businessId?: number,
	userPhone?: string,
	country?: string,
	no?: string,
	deliverNo?: string,
	deliverFee?: number,
	deliverStatus?: any,
	cwareId?: number,
	insurer?: string,
	insurfee?: number,
	referenceId?: string,
	shipmentId?: string,
	addressId?: number,
	topAddr?: any,
	topSender?: any,
	addr?: string,
	sender?: string,
	ship?: number,
	preShip?: number,
	unitPrice?: number,
	lastUnitPrice?: number,
	feeUser?: number,
	feeComp?: number,
	quantity?: number,
	box?: number,
	boxPics?: [],
	boxPdf?: string,
	declare?: number,
	dcur?: string,
	deliver?: string,
	deliverType?: any,
	channel?: string,
	channelCode?: string,
	templateId?: number,
	templateName?: string,
	method?: any,
	days?: string,
	state?: any,
	paymentState?: any,
	wareCode?: string,
	supplyCode?: string,
	exams?: [],
	weight?: number,
	lastFeeKg?: number,
	feeWeight?: number,
	size?: number,
	traces?: [],
	memo?: string,
	remark?: string,
	log?: [],
	ptime?: string,
	track17Key?: string,
	goods?: string,
	loss?: number,
	remote?: boolean,
	boxDtoList?: []
}

export interface IShipOrder {
	extraId?: number,
	specadd?: number,
	pid?: number,
	child?: number,
	id?: number,
	userId?: number,
	businessId?: number,
	userPhone?: string,
	country?: string,
	no?: string,
	deliverNo?: string,
	deliverFee?: number,
	deliverStatus?: any,
	cwareId?: number,
	insurer?: string,
	insurfee?: number,
	referenceId?: string,
	shipmentId?: string,
	addressId?: number,
	topAddr?: any,
	topSender?: any,
	addr?: string,
	sender?: string,
	ship?: number,
	preShip?: number,
	unitPrice?: number,
	lastUnitPrice?: number,
	feeUser?: number,
	feeComp?: number,
	quantity?: number,
	box?: number,
	boxPics?: [],
	boxPdf?: string,
	declare?: number,
	dcur?: string,
	deliver?: string,
	deliverType?: any,
	channel?: string,
	channelCode?: string,
	templateId?: number,
	templateName?: string,
	method?: any,
	days?: string,
	state?: any,
	paymentState?: any,
	wareCode?: string,
	supplyCode?: string,
	exams?: [],
	weight?: number,
	lastFeeKg?: number,
	feeWeight?: number,
	size?: number,
	traces?: [],
	memo?: string,
	remark?: string,
	log?: [],
	ptime?: string,
	track17Key?: string,
	goods?: string,
	loss?: number,
	remote?: boolean,
	boxDtoList?: []
}

export interface IShipOrderMemo {
	orderId?: number,
	memo?: string
}

export interface IShipDeliverNo {
	orderId?: number,
	deliverNo?: string
}

export interface IBoxPdf {
	orderId?: number,
	pdfUrl?: string
}
export interface IBoxImg {
	orderId?: number,
	boxPics?: []
}

export interface IBoxImg {
	orderId?: number,
	boxPics?: []
}

export interface IShipOrder {
	extraId?: number,
	specadd?: number,
	pid?: number,
	child?: number,
	id?: number,
	userId?: number,
	businessId?: number,
	userPhone?: string,
	country?: string,
	no?: string,
	deliverNo?: string,
	deliverFee?: number,
	deliverStatus?: any,
	cwareId?: number,
	insurer?: string,
	insurfee?: number,
	referenceId?: string,
	shipmentId?: string,
	addressId?: number,
	topAddr?: any,
	topSender?: any,
	addr?: string,
	sender?: string,
	ship?: number,
	preShip?: number,
	unitPrice?: number,
	lastUnitPrice?: number,
	feeUser?: number,
	feeComp?: number,
	quantity?: number,
	box?: number,
	boxPics?: [],
	boxPdf?: string,
	declare?: number,
	dcur?: string,
	deliver?: string,
	deliverType?: any,
	channel?: string,
	channelCode?: string,
	templateId?: number,
	templateName?: string,
	method?: any,
	days?: string,
	state?: any,
	paymentState?: any,
	wareCode?: string,
	supplyCode?: string,
	exams?: [],
	weight?: number,
	lastFeeKg?: number,
	feeWeight?: number,
	size?: number,
	traces?: [],
	memo?: string,
	remark?: string,
	log?: [],
	ptime?: string,
	track17Key?: string,
	goods?: string,
	loss?: number,
	remote?: boolean,
	boxDtoList?: []
}

export interface ITrackTrace {
	orderId?: number,
	regist17?: boolean,
	traceNo?: string,
	companyId?: number,
	track17Key?: number,
	traceName?: string,
	method?: any,
	country?: string,
	state?: any
}
export interface ISupplier {
	orderId?: number,
	orderNo?: string,
	track17Key?: number,
	trackNo?: [],
	shipmentId?: string,
	companyId?: number
}
export interface IKOrderResult {
	sign?: string,
	timestamp?: string,
	content?: []
}
export interface ISFOrderStateBack {
	requestId?: string,
	timestamp?: number,
	orderState?: []
}
export interface IHLLOrderStateBack {
	serial_no?: string,
	action?: string,
	create_time?: number,
	data?: any
}
export interface IChannelParam {
	id?: number,
	countryCode?: string,
	method?: number
}
export interface IAddress {
	id?: number,
	userId?: number,
	loginName?: string,
	isDefault?: boolean,
	ptime?: string,
	topAddr?: any,
	addr?: string
}
export interface ILogin {
	phone?: string,
	password?: string,
	type?: any,
	id?: string,
	track?: any,
	captcha?: string,
	joint?: string
}
export interface INewPwd {
	phone?: string,
	password?: string,
	newPassword?: string,
	captcha?: string
}
export interface ISendCaptcha {
	ipAddress?: string,
	phone?: string,
	captchaType?: any
}
export interface IGetAmzFee {
	countryCode?: string,
	productType?: any,
	height?: number,
	heightIn?: number,
	length?: number,
	lengthIn?: number,
	width?: number,
	widthIn?: number,
	weight?: number,
	weightLb?: number
}
export interface IShipOrder {
	extraId?: number,
	specadd?: number,
	pid?: number,
	child?: number,
	id?: number,
	userId?: number,
	businessId?: number,
	userPhone?: string,
	country?: string,
	no?: string,
	deliverNo?: string,
	deliverFee?: number,
	deliverStatus?: any,
	cwareId?: number,
	insurer?: string,
	insurfee?: number,
	referenceId?: string,
	shipmentId?: string,
	addressId?: number,
	topAddr?: any,
	topSender?: any,
	addr?: string,
	sender?: string,
	ship?: number,
	preShip?: number,
	unitPrice?: number,
	lastUnitPrice?: number,
	feeUser?: number,
	feeComp?: number,
	quantity?: number,
	box?: number,
	boxPics?: [],
	boxPdf?: string,
	declare?: number,
	dcur?: string,
	deliver?: string,
	deliverType?: any,
	channel?: string,
	channelCode?: string,
	templateId?: number,
	templateName?: string,
	method?: any,
	days?: string,
	state?: any,
	paymentState?: any,
	wareCode?: string,
	supplyCode?: string,
	exams?: [],
	weight?: number,
	lastFeeKg?: number,
	feeWeight?: number,
	size?: number,
	traces?: [],
	memo?: string,
	remark?: string,
	log?: [],
	ptime?: string,
	track17Key?: string,
	goods?: string,
	loss?: number,
	remote?: boolean,
	boxDtoList?: []
}
 
export const Api = {
	ExtraOrder: {
		CreateExtraOrder: (params: IExtraOrder) => {
			return request({method: 'POST', url: '/api/top/extraorder/CreateExtraOrder', data: params})
		},
		GetCurrent: () => {
			return request({method: 'GET', url: '/api/top/extraorder/GetCurrent'})
		},
		Finish: (params: { id: number }) => {
			return request({method: 'GET', url: '/api/top/extraorder/Finish', params: params})
		},
		GetDetail: (params: { OrderId: number }) => {
			return request({method: 'GET', url: '/api/top/extraorder/Detail', params: params})
		},
		GetOrderList: (params: { KeyWord?: string, Status?: any, PayStatus?: any, Begin?: string, End?: string, StartPayTime?: string, EndPayTime?: string, BusinessId?: number, PageSize?: number, Page?: number, Total?: number }) => {
			return request({method: 'GET', url: '/api/top/extraorder/GetOrderList', params: params})
		},
		GetConfigs: () => {
			return request({method: 'GET', url: '/api/top/extraorder/GetConfigs'})
		},
		BoxDetail: (params: { OrderId: number }) => {
			return request({method: 'GET', url: '/api/top/extraorder/BoxDetail', params: params})
		},
		CreateTopOrder: (params: { OrderId: number }) => {
			return request({method: 'GET', url: '/api/top/extraorder/CreateTopOrder', params: params})
		},
	},
	Goods: {
		GetGoodsList: (params: { UserId?: number, Keyword?: string, Country?: string, Method?: any, HasSpecial?: any, PageSize?: number, Page?: number, Total?: number }) => {
			return request({method: 'GET', url: '/api/top/goods/GetGoodsList', params: params})
		},
		GetSpecialAddPrice: (params: { Country?: string, Method?: any, Ids?: any }) => {
			return request({method: 'GET', url: '/api/top/goods/GetSpecialAddPrice', params: params})
		},
		SaveGoods: (params: IGoods) => {
			return request({method: 'POST', url: '/api/top/goods/SaveGoods', data: params})
		},
		UploadGoods: (params: { file: undefined }) => {
			return request({method: 'POST', url: '/api/top/goods/UploadGoods', data: params})
		},
		DeleteGoods: (params: { id: number }) => {
			return request({method: 'GET', url: '/api/top/goods/DeleteGoods', params: params})
		},
		BatchDelete: (params: { ids: string }) => {
			return request({method: 'GET', url: '/api/top/goods/BatchDelete', params: params})
		},
	},
	Pack: {
		GetPackList: (params: { Keyword?: string, UserId?: number, UserName?: string, cn?: string, PageSize?: number, Page?: number, Total?: number }) => {
			return request({method: 'GET', url: '/api/top/pack/List', params: params})
		},
		SavePack: (params: IPack) => {
			return request({method: 'POST', url: '/api/top/pack/Save', data: params})
		},
		DeleteById: (params: { id: number }) => {
			return request({method: 'GET', url: '/api/top/pack/Delete', params: params})
		},
	},
	WareBook: {
		AddZwarebook: (params: IWareBook) => {
			return request({method: 'PUT', url: '/api/top/warebook/AddZwarebook', data: params})
		},
		GetZwarebookList: (params: { wareCode?: string, status?: number }) => {
			return request({method: 'GET', url: '/api/top/warebook/ZwarebookList', params: params})
		},
	},
	Ware: {
		GetWareList: (params: IWareParam) => {
			return request({method: 'POST', url: '/api/top/ware/List', data: params})
		},
		GetZwareCodeList: () => {
			return request({method: 'GET', url: '/api/top/ware/GetZwareCodeList'})
		},
		GetWareByCode: (params: { wareCode: string }) => {
			return request({method: 'GET', url: '/api/top/ware/GetWareByCode', params: params})
		},
		GetWareAndGroup: () => {
			return request({method: 'GET', url: '/api/top/ware/GetWareAndGroup'})
		},
	},
	TimeBook: {
		AddTimebook: (params: ITimeBook) => {
			return request({method: 'PUT', url: '/api/top/timebook/AddTimebook', data: params})
		},
		GetTimebookList: (params: { timeId?: number, status?: number }) => {
			return request({method: 'GET', url: '/api/top/timebook/TimebookList', params: params})
		},
	},
	Special: {
		GetSpeAddList: (params: ISpeAddParam) => {
			return request({method: 'POST', url: '/api/top/special/SpeAddList', data: params})
		},
	},
	Ship: {
		GetShipList: (params: IShipParam) => {
			return request({method: 'POST', url: '/api/top/ship/GetShipList', data: params})
		},
	},
	Resource: {
		GetResourceList: (params: IResQuery) => {
			return request({method: 'POST', url: '/api/top/resource/GetResourceList', data: params})
		},
		GetDetail: (params: { id: number }) => {
			return request({method: 'POST', url: '/api/top/resource/GetDetail', params: params})
		},
	},
	Order: {
		GetListByGood: (params: { Goodsid?: number, PageSize?: number, Page?: number, Total?: number }) => {
			return request({method: 'GET', url: '/api/top/order/GetListByGood', params: params})
		},
		GetZorderList: (params: { Keyword?: string, Country?: string, CompanyId?: number, OrderState?: any, PaymentState?: any, Method?: any, StartTime?: string, EndTime?: string, PageSize?: number, Page?: number, Total?: number }) => {
			return request({method: 'GET', url: '/api/top/order/GetZorderList', params: params})
		},
		GetOrderDetail: (params: { orderId: number }) => {
			return request({method: 'GET', url: '/api/top/order/GetOrderDetail', params: params})
		},
		CreateOrder: (params: IShipOrder) => {
			return request({method: 'PUT', url: '/api/top/order/CreateOrder', data: params})
		},
		ChooseChannel: (params: IShipOrder) => {
			return request({method: 'POST', url: '/api/top/order/ChooseChannel', data: params})
		},
		UpdateMemo: (params: IShipOrderMemo) => {
			return request({method: 'POST', url: '/api/top/order/UpdateMemo', data: params})
		},
		SaveDeliverNo: (params: IShipDeliverNo) => {
			return request({method: 'POST', url: '/api/top/order/SaveDeliverNo', data: params})
		},
		SaveBoxPdf: (params: IBoxPdf) => {
			return request({method: 'POST', url: '/api/top/order/SaveBoxPdf', data: params})
		},
		UploadBoxImg: (params: IBoxImg) => {
			return request({method: 'POST', url: '/api/top/order/UploadBoxImg', data: params})
		},
		DeleteBoxImg: (params: IBoxImg) => {
			return request({method: 'POST', url: '/api/top/order/DeleteBoxImg', data: params})
		},
		GetDeliverFee: (params: IShipOrder) => {
			return request({method: 'POST', url: '/api/top/order/DeliverFee', data: params})
		},
		UploadShipment: (params: { file: undefined }) => {
			return request({method: 'POST', url: '/api/top/order/UploadShipment', data: params})
		},
		GetTraceDetail: (params: ITrackTrace) => {
			return request({method: 'POST', url: '/api/top/order/GetTraceDetail', data: params})
		},
		GetOrderLabel: (params: ISupplier) => {
			return request({method: 'POST', url: '/api/top/order/GetOrderLabel', data: params})
		},
		ReceiveSdgj: () => {
			return request({method: 'POST', url: '/api/top/order/ReceiveSdgj'})
		},
	},
	Insure: {
		ReceiveKOrders: (params: IKOrderResult) => {
			return request({method: 'POST', url: '/api/top/insure/ReceiveKOrders', data: params})
		},
	},
	Division: {
		Init: () => {
			return request({method: 'PUT', url: '/api/top/division/Init'})
		},
		GetHotCitys: () => {
			return request({method: 'GET', url: '/api/top/division/GetHotCitys'})
		},
		GetDivisions: (params?: { ParentCode: string }) => {
			return request({method: 'GET', url: '/api/top/division/GetDivisions', params: params})
		},
	},
	Deliver: {
		DepponPrice: (params: { dStateReceive: undefined }) => {
			return request({method: 'POST', url: '/api/top/deliver/depponPrice', data: params})
		},
		SFPrice: (params: { sfFee: undefined }) => {
			return request({method: 'POST', url: '/api/top/deliver/SFPrice', data: params})
		},
		SFOrderState: (params: ISFOrderStateBack) => {
			return request({method: 'POST', url: '/api/top/deliver/SFOrderState', data: params})
		},
		KYOrderState: (params: { kYRouteBacks: undefined }) => {
			return request({method: 'POST', url: '/api/top/deliver/KYOrderState', data: params})
		},
		HLLOrderState: (params: IHLLOrderStateBack) => {
			return request({method: 'POST', url: '/api/top/deliver/HLLOrderState', data: params})
		},
	},
	Country: {
		GetZcountryList: () => {
			return request({method: 'GET', url: '/api/top/country/GetZcountryList'})
		},
		GetAreaList: () => {
			return request({method: 'GET', url: '/api/top/country/GetAreaList'})
		},
		GetAreaList2: (params?: { country: string }) => {
			return request({method: 'GET', url: '/api/top/country/GetAreaList2', params: params})
		},
		GetEUCountrys: () => {
			return request({method: 'GET', url: '/api/top/country/GetEUCountrys'})
		},
		GetWareCountrys: () => {
			return request({method: 'GET', url: '/api/top/country/GetWareCountrys'})
		},
		GetOriginList: () => {
			return request({method: 'GET', url: '/api/top/country/GetOriginList'})
		},
	},
	Channel: {
		GetDetailById: (params: { id: number }) => {
			return request({method: 'GET', url: '/api/top/channel/Detail', params: params})
		},
		GetChannelList: (params: IChannelParam) => {
			return request({method: 'POST', url: '/api/top/channel/List', data: params})
		},
	},
	Address: {
		GetAddressList: () => {
			return request({method: 'GET', url: '/api/top/address/GetAddressList'})
		},
		SaveAddress: (params: IAddress) => {
			return request({method: 'POST', url: '/api/top/address/SaveAddress', data: params})
		},
		DeleteAddress: (params: { id: number }) => {
			return request({method: 'DELETE', url: '/api/top/address/DeleteAddress', params: params})
		},
		SetDefault: (params: { id: number }) => {
			return request({method: 'PUT', url: '/api/top/address/SetDefault', params: params})
		},
		GetDefault: () => {
			return request({method: 'GET', url: '/api/top/address/GetDefault'})
		},
	},
	Account: {
		GetUserDetail: () => {
			return request({method: 'GET', url: '/api/top/account/Detail'})
		},
		LoginAsync: (params: ILogin) => {
			return request({method: 'PUT', url: '/api/top/account/login', data: params})
		},
		ExistAsync: (params: { phone: string }) => {
			return request({method: 'GET', url: '/api/top/account/exist', params: params})
		},
		resetPwdAsync: (params: INewPwd) => {
			return request({method: 'POST', url: '/api/top/account/resetPwd', data: params})
		},
		LogoutAsync: () => {
			return request({method: 'DELETE', url: '/api/top/account/logout'})
		},
		SendCaptchaAsync: (params: ISendCaptcha) => {
			return request({method: 'POST', url: '/api/top/account/send-captcha', data: params})
		},
	},
	Tool: {
		ChangePDF: (params: { file: undefined }) => {
			return request({method: 'POST', url: '/api/top/deliverskill/ChangePDF', data: params})
		},
		GetPDF: (params: { fileName: string }) => {
			return request({method: 'GET', url: '/api/top/deliverskill/DownloadPDF', params: params})
		},
		GetTRexs: (params: { CountryCode: string }) => {
			return request({method: 'GET', url: '/api/top/deliverskill/GetTRexs', params: params})
		},
		GetLastAmzFee: (params: IGetAmzFee) => {
			return request({method: 'POST', url: '/api/top/deliverskill/GetLastAmzFee', data: params})
		},
		ImportUsAmzFee: (params: { file: undefined }) => {
			return request({method: 'POST', url: '/api/top/deliverskill/ImportUsAmzFee', data: params})
		},
		ImportEuAmzFee: (params: { file: undefined }) => {
			return request({method: 'POST', url: '/api/top/deliverskill/ImportEuAmzFee', data: params})
		},
	},
	Price: {
		GetLastPrice: (params: IShipOrder) => {
			return request({method: 'POST', url: '/api/top/price/GetLastPrice', data: params})
		},
	},
	Oss: {
		GetToken: () => {
			return request({method: 'GET', url: '/api/top/oss/GetToken'})
		},
		UploadImg: (params: { ImgType?: any, Files?: any }) => {
			return request({method: 'POST', url: '/api/top/oss/UploadImg', data: params})
		},
		UploadEXl: (params: { ExlType?: any, Files?: any }) => {
			return request({method: 'POST', url: '/api/top/oss/UploadExl', data: params})
		},
		UploadDocuments: (params: { files: undefined }) => {
			return request({method: 'POST', url: '/api/top/oss/UploadDocuments', data: params})
		},
		UploadBoxPDF: (params: { file: undefined }) => {
			return request({method: 'POST', url: '/api/top/oss/UploadBoxPDF', data: params})
		},
		UploadSkuLabel: (params: { Sku?: string, file?: any }) => {
			return request({method: 'POST', url: '/api/top/oss/UploadSkuLabel', data: params})
		},
	},
}