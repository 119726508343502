import { Api } from '@/api';
import Modal from "@/components/Modal";
import { AppContext, APPEvent } from '@/ZRender/context';
import { getUITagParams } from '@/ZRender/helper';
import React, { useContext, useEffect, useState } from "react";
import {Message} from "@/components/Messsage";
import './index.less'
import Capt from "@/pages/_global/capt";

let aTimer: any;
let press: boolean = true

const Login = () => {
    const [count, setCount] = useState<number>(60)
    const [phone, setPhone] = useState<string>('');
    const [visible, setVisible] = useState(false)
    const [password, setPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState();
    const [joint, setJoint] = useState();

    const [captcha, setCaptcha] = useState();
    const [tip, setTip] = useState<boolean>(false)

    // const [isLogin, setIsLogin] = useState(false);
    const [status, setStatus] = useState<number>(1)  // 1 登录   2 注册    3 忘记密码

    const [show, setShow] = useState<boolean>(false)
    const [sliding, setSliding] = useState<any>('')
    const [values, setValues] = useState<any>()
    const [flag, setFlag] = useState<number>(1) // 验证状态  1： 未验证   2：通过    3：未通过
    const [enCode, setEncode] = useState<string>();


    //@ts-ignore
    const {state, dispatch} = useContext(AppContext)


    const initDom = () => {
        const gcontrol: any = document.querySelector('*[ui=gcontrol]');
        const {params} = getUITagParams(gcontrol);
        const loginBtn = document.getElementsByClassName('theme-login') as any;
        const usernameBtn = document.getElementsByClassName('username') as any;

        const loginOutBtn = document.getElementsByClassName('theme-logout') as any;
        // setIsLogin(params.islogin == '1');
        setStatus(1)
        dispatch({
            type: APPEvent.SETISLOGIN,
            state: {isLogin: params.islogin == '1'}
        })

        if (loginBtn && loginBtn.length > 0) {
            loginBtn[0].onclick = () => {
                setStatus(1)
                dispatch({
                    type: APPEvent.SHOWLOGIN,
                    state: { showLogin:true }
                })
                setVisible(true)
            }
        }
        if (loginOutBtn && loginOutBtn.length > 0) {
            loginOutBtn[0].onclick = () => {
                Api.Account.LogoutAsync().then(() => {
                    window.location.reload()
                })
            }
        }
        if (usernameBtn && usernameBtn.length > 0) {
            usernameBtn[0].onclick = () => {
                location.href = '/Account/Goods.html'
            }
        }


    }

    useEffect(() => {
        initDom();
        if (localStorage.getItem('phone')) {
            setPhone(localStorage.getItem('phone') || '')
        }
        if (localStorage.getItem('fwa')) {
            setPassword(localStorage.getItem('fwa') || '')
        }
    }, [])
    const doAction = () => {
        if (phone?.length !== 11 || !password) return

        if (status !== 3) {
            handleSubmit()
        }
        if (status === 3) {
            if (!captcha) {
                return
            }
            if (password !== newPassword) {
                setTip(true)
                return;
            }
            Api.Account.resetPwdAsync({phone, password, newPassword, captcha}).then(() => {
                Message.success('密码重置成功')
                setStatus(1)
            })
        }

    }

    useEffect(() => {
        setVisible(state.showLogin)
    }, [state.showLogin])

    const onCancel = () => {
        dispatch({
            type: APPEvent.SHOWLOGIN,
            state: { showLogin:false }
        })
    }
    // 倒计时
    const fn = (num: number) => {
        let n = num
        if(n>0){
            aTimer=setTimeout(() => {
                n--;
                fn(n)
            },1000)
            setCount(n)
        } else {
            clearTimeout(aTimer)
            setCount(60)
        }
    }
    // 发送验证码
    const getCode = () => {
        if (count !== 60 || !phone) return
        Api.Account.SendCaptchaAsync({phone, captchaType: status === 2 && 1 || 2}).then(() => {
            Message.success('发送成功')
            fn(count)
        })
    }


    const handleSubmit = () => {
        const e = {phone, password}
        setValues(e)
        if (sliding) {
            setShow(true)
        } else {
            onLogin({
                ...e,
                id: enCode || '',
                password,
                captcha,
                joint
            })
        }
    };
    // 登录
    const onLogin = async (values: any) => {
        try {
            const res: any = await Api.Account.LoginAsync({...values, type: status});
            const msg = res.data
            if (msg) {
                setEncode(msg.id);// 加密字段，用于验证码登录
                if (msg.success) {
                    setSliding(undefined)
                    setShow(false)
                    setFlag(2)
                    localStorage.setItem('phone', phone)
                    localStorage.setItem('fwa', password)
                    window.location.reload()
                    return;
                } else {
                    if (msg.error) {
                        Message.error(msg.error);
                    }
                    setSliding(msg.sliding)
                    setFlag(3)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    //
    useEffect(() => {
        if (sliding) {
            setShow(true)
        }
    }, [sliding])
    // 滑块结束登录
    const onFinish = (e: any) => {
        setFlag(1)
        setSliding(undefined)
        onLogin({...values, ...e, password})
    }


    useEffect(() => {
        const handleKeydown = (e: any) => {
            if (e.keyCode === 13 && press) {
                doAction()
                press = false
            }
        }
        if (visible) {
            window.addEventListener('keydown', handleKeydown)
            window.addEventListener('keyup', () => press = true)
        }
        return () => window.removeEventListener("keydown", handleKeydown);
    }, [visible, status, sliding, phone, password, captcha])


    return (
        <>
            <input type="password" name='Password' style={{position: 'fixed', opacity: 0, zIndex: -1}}/>
            <Modal visible={visible} footer={false} width={413} maxHeight={600} onCancel={onCancel}
                   title={<div
                       className='login-title'>欢迎{status === 3 && '重置密码' || status === 2 && '注册' || '登录'} </div>}>
                <div className="login_container">

                    <div className="login_password">
                        {
                            status !== 1 && <em>已有账号！ <a onClick={() => setStatus(1)}>直接登录</a> </em>
                            ||
                            <em>没有账号? <a onClick={() => setStatus(2)}>立即注册</a> </em>
                        }
                        <ul className="login_ul">
                            <li>
                                <label>账&nbsp;&nbsp;&nbsp;号</label>
                                <input type="text" name="Phone" placeholder="请输入手机号"
                                       defaultValue={localStorage.getItem('phone') || ''} pattern='^1[3456789]\d{9}$'
                                       required={true} onChange={(e: any) => setPhone(e.target.value)}/>
                            </li>

                            <li>
                                {status === 3 && <label>新&nbsp;密&nbsp;码</label> || <label>密&nbsp;&nbsp;&nbsp;码</label>}
                                <input type='password' name='Password' autoComplete='off' required
                                       placeholder="请输入8-16位数字和字母组合"
                                       defaultValue={localStorage.getItem('fwa') || ''}
                                       onChange={(e: any) => setPassword(e.target.value)}/>
                            </li>
                            {status === 3 && <li>
                                <label>确认密码</label>
                                <input type='password' name='newPassword' autoComplete='off' placeholder='请确认密码'
                                       required onChange={(e: any) => setNewPassword(e.target.value)}/>
                                <div id="phone-error" className="error">{tip && '两次输入密码不一致' || ''}</div>
                            </li> || ''}
                            {
                                status !== 1
                                &&
                                <div>
                                    <li>
                                        <label>验证码</label>
                                        <input type="text" name="captcha" placeholder="请输入验证码" required
                                               onChange={(e: any) => setCaptcha(e.target.value)}/>
                                        <button className="jiaoyan-btn" id="codeSms"
                                                onClick={getCode}>{(count === 60 || count === 1) && '获取验证码' || `重新发送(${count}s)`}</button>
                                    </li>
                                    {
                                        status === 2 &&

                                        <li>
                                            <label>邀请码</label>
                                            <input type='text' name='joint' autoComplete='off' placeholder='请输入邀请码（选填）'
                                                   onChange={(e: any) => setJoint(e.target.value)}/>
                                        </li>
                                        || ''
                                    }
                                </div>

                                || ''
                            }
                            <div style={{textAlign: 'right'}}>
                                {status !== 3 && <em><a onClick={() => setStatus(3)}>忘记密码?</a> </em>}
                            </div>
                            {show && status === 1 &&
                            <Capt onFinish={onFinish} sliding={sliding} status={flag} setVisible={setShow}/> || ''}
                            <button className="login_btn" onClick={doAction}>
                                {status === 3 && '确定' || status === 2 && '注册' || '登录'}
                            </button>
                        </ul>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Login;