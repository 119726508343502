import ZIcon from "@/components/Icon";
import React from "react";
import ReactDOM from "react-dom";
import { IModalProps } from "../interface";
import ModalWrap from "../Wrap";

export interface IModalConfirmProps extends Omit<IModalProps, 'onCancel'> {
    onCancel?: () => void
}

export type ModalMessageTypes = 'info' | 'warn' | 'tips' | 'confirm' | 'error'

export const DialogRender = (props: IModalConfirmProps, type: ModalMessageTypes) => {

    const onClose = () => {
                closeModal();
                props.onCancel && props.onCancel();
           
    }


    const FlagIcon = {
        'info':<ZIcon type="icon-warn"    style={{fontSize: '18px',marginRight:'5px'}}/>,
        'warn':<ZIcon type="icon-warn"    style={{fontSize: '18px',marginRight:'5px'}}/>,
        'tips':<ZIcon type="icon-warn"    style={{fontSize: '18px',marginRight:'5px'}}/>,
        'confirm':<ZIcon type="icon-warn" style={{fontSize: '18px',marginRight:'5px'}}/>,
        'error':<ZIcon type="icon-error"  style={{fontSize: '18px',marginRight:'5px'}}/>
    }

    // body 添加节点
    const div = document.createElement('div');
    document.body.append(div);
    document.body.setAttribute('class', 'zc-modal-scrolling');


    function render() {
        setTimeout(() => {
            ReactDOM.render(<ModalWrap
                                {...props}
                                icon={FlagIcon[type]}    
                                title={props.title}
                                showConfirm={true}
                                showCancel={true}
                                type={type}
                                visible={true}
                                onCancel={() => onClose()} onOk={props.onOk} />, div);
        })
    }
    function closeModal() {
        const unmountResult = ReactDOM.unmountComponentAtNode(div);
        if (unmountResult && div.parentNode) {
            div.parentNode.removeChild(div);
            document.body.removeAttribute('class');
        }
    }
    render();
}


export default DialogRender;