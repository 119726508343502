import React, {useReducer} from "react";


interface IProps{
    children?:any
}

export interface IZCState{
    clickDom?:any,
    messageQuene?:any
}
export enum ZCEvent { addMessage,addClickDom }


export const ZCContext = React.createContext<{state?:IZCState,dispatch:(e?:{type?:ZCEvent,state?:any})=>void}>({dispatch:()=>{}});

export const ZCProvider = (props:IProps)=>{

    const { children } = props;
    const reducer = (state: any,action:{type:ZCEvent,state?:IZCState}) => {
      switch (action.type) {
               // 全局window 点击事件
          case ZCEvent.addClickDom:
              return {...state, clickDom:action.state};
               // Message组件队列
          case ZCEvent.addMessage:
              return {...state, messageQuene:[...state.messageQuene,action.state]};
          default:
              return state;
      }
    }

    const [state] = useReducer(reducer, {
        clickDom: undefined,//当前活动项
        messageQuene: []
    });


    // useEffect(()=>{
    //     document.onclick = (e: any) => {
    //         dispatch({type: ZCEvent.addClickDom, state: e.target})
    //     }
    // },[])


    return (
        <ZCContext.Provider value={state}>
            {children}
        </ZCContext.Provider>
    )

}